var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import ChannelWrapper from '../Components/ChannelWrapper';
import { getAction, getError, getData } from '../../../redux/channels';
var ChannelsPage = /** @class */ (function (_super) {
    __extends(ChannelsPage, _super);
    function ChannelsPage(props) {
        return _super.call(this, props) || this;
    }
    ChannelsPage.prototype.componentDidMount = function () {
        var _this = this;
        this.props.getAction();
        this.timerId = setInterval(function () {
            _this.props.getAction();
        }, 5000);
    };
    ChannelsPage.prototype.componentWillUnmount = function () {
        clearInterval(this.timerId);
    };
    ChannelsPage.prototype.render = function () {
        var _a = this.props.data.live, servers = _a === void 0 ? [] : _a;
        var error = this.props.error;
        if (error)
            return React.createElement(Alert, { color: "danger" }, error);
        if (servers.length === 0)
            return React.createElement(Alert, { color: "info" }, "No servers online.");
        return (React.createElement("div", null, servers.map(function (_a, id) {
            var server = _a.server, apps = _a.apps;
            if (apps.length === 0) {
                return (React.createElement(Alert, { key: id, color: "info" },
                    server,
                    " / apps: ",
                    apps.length));
            }
            return (React.createElement("div", { key: id },
                React.createElement(Alert, { key: id, color: "success" }, server),
                apps.map(function (_a, serverId) {
                    var app = _a.app, channels = _a.channels;
                    return channels.map(function (_a, channelId) {
                        var channel = _a.channel, publisher = _a.publisher, subscribers = _a.subscribers;
                        return (React.createElement("div", { key: server + "-" + app + "-" + channel + "}" },
                            React.createElement(ChannelWrapper, { stream: publisher, subscribers: subscribers }),
                            React.createElement("br", null)));
                    });
                })));
        })));
    };
    ChannelsPage = __decorate([
        connect(function (state) { return ({
            error: getError(state),
            data: getData(state),
        }); }, { getAction: getAction })
    ], ChannelsPage);
    return ChannelsPage;
}(Component));
export default ChannelsPage;
