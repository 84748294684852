import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import injectTapEventPlugin from 'react-tap-event-plugin';
import { BrowserRouter } from 'react-router-dom';
import App from './modules/App/Containers/App/App';
import store from './store';
import 'normalize.css/normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-table/react-table.css';
injectTapEventPlugin();
var appElement = document.createElement('div');
appElement.setAttribute('id', 'app');
document.body.appendChild(appElement);
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(BrowserRouter, null,
        React.createElement(App, null))), document.getElementById('app'));
