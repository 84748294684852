var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import ReactTable from 'react-table';
import streamsTable from '../../Shared/Tables/StreamsTable';
import subscribersTable from '../../Shared/Tables/SubscribersTable';
var ChannelWrapper = /** @class */ (function (_super) {
    __extends(ChannelWrapper, _super);
    function ChannelWrapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChannelWrapper.prototype.render = function () {
        var _a = this.props, _b = _a.stream, stream = _b === void 0 ? null : _b, _c = _a.subscribers, subscribers = _c === void 0 ? [] : _c;
        var streams = [];
        if (stream)
            streams.push(stream);
        return (React.createElement("div", null,
            React.createElement(ReactTable, { columns: streamsTable(undefined, ['connectUpdated']), data: streams, showPagination: false, minRows: 0, sortable: false }),
            subscribers.length > 0 ? (React.createElement("div", null,
                React.createElement(ReactTable, { columns: subscribersTable(undefined, undefined, [
                        'connectUpdated',
                        'app',
                        'channel',
                    ]), data: subscribers, showPagination: false, showPageSizeOptions: false, minRows: 0, className: "-striped -highlight", sortable: false }), "Subscribers: " + subscribers.length)) : (React.createElement("div", null))));
    };
    return ChannelWrapper;
}(Component));
export default ChannelWrapper;
