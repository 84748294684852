import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import humanize from 'humanize-plus';
import _ from 'lodash';
import humanizeDuration from 'humanize-duration';
var tableConfigTemplate = [
    {
        Header: 'Connect Created',
        accessor: 'connectCreated',
        Cell: function (props) {
            return (React.createElement(Link, { to: '/subscribers/' + props.original._id }, moment(props.value).format('ddd D/MMM/YY HH:mm')));
        },
    },
    {
        Header: 'App',
        accessor: 'app',
        minWidth: 40,
    },
    {
        Header: 'Channel',
        accessor: 'channel',
        minWidth: 40,
    },
    {
        Header: 'Last Update',
        accessor: 'connectUpdated',
        Cell: function (props) {
            return props.original.isLive
                ? 'Live!'
                : moment(props.value).format('ddd D/MMM/YY HH:mm');
        },
    },
    {
        Header: 'Bitrate',
        accessor: 'bitrate',
        Cell: function (props) {
            return props.value + " kbps";
        },
        minWidth: 40,
    },
    {
        Header: 'Net Traffic',
        accessor: 'bytes',
        Cell: function (props) {
            return humanize.fileSize(props.value);
        },
        minWidth: 40,
    },
    {
        Header: 'Duration',
        accessor: 'duration',
        Cell: function (props) {
            return humanizeDuration(props.value * 1000, {
                round: true,
                largest: 2,
                language: 'shortEn',
                languages: {
                    shortEn: {
                        y: 'y',
                        mo: 'mo',
                        w: 'w',
                        d: 'd',
                        h: 'h',
                        m: 'm',
                        s: 'sec',
                        ms: 'ms',
                    },
                },
            });
        },
        minWidth: 40,
    },
    {
        Header: 'IP',
        accessor: 'ip',
        Cell: function (props) {
            var value = props.value;
            if (props.original.countryCode && props.original.city) {
                value = value + " (" + props.original.countryCode + "/" + props.original.city + ")";
            }
            if (props.original.userName) {
                value = value + " (" + props.original.userName + ")";
            }
            return value;
        },
    },
    {
        Header: 'Protocol',
        accessor: 'protocol',
        minWidth: 40,
    },
];
function tableConfigOptions(options, disableFiltering, removeColumns) {
    if (options === void 0) { options = {}; }
    if (disableFiltering === void 0) { disableFiltering = []; }
    if (removeColumns === void 0) { removeColumns = []; }
    var tableConfig = _.cloneDeep(tableConfigTemplate);
    for (var _i = 0, removeColumns_1 = removeColumns; _i < removeColumns_1.length; _i++) {
        var removeColumn = removeColumns_1[_i];
        _.remove(tableConfig, { accessor: removeColumn });
    }
    _.forEach(disableFiltering, function (columnName) {
        var tableColumn = _.find(tableConfig, ['accessor', columnName]);
        if (tableColumn) {
            tableColumn.filterable = false;
        }
    });
    var appsTableColumn = _.find(tableConfig, ['accessor', 'app']);
    if (appsTableColumn) {
        appsTableColumn.Filter = function (_a) {
            var filter = _a.filter, onChange = _a.onChange;
            return (React.createElement("div", null,
                React.createElement("input", { list: "apps", name: "apps", onChange: function (event) { return onChange(event.target.value); }, style: { width: '100%' }, value: filter ? filter.value : '' }),
                React.createElement("datalist", { id: "apps" },
                    React.createElement("option", { value: "" }),
                    _.map(options.apps, function (app, id) {
                        return (React.createElement("option", { key: id, value: app }, app));
                    }))));
        };
    }
    var channelsTableColumn = _.find(tableConfig, ['accessor', 'channel']);
    if (channelsTableColumn) {
        channelsTableColumn.Filter = function (_a) {
            var filter = _a.filter, onChange = _a.onChange;
            return (React.createElement("div", null,
                React.createElement("input", { list: "channels", name: "channels", onChange: function (event) { return onChange(event.target.value); }, style: { width: '100%' }, value: filter ? filter.value : '' }),
                React.createElement("datalist", { id: "channels" },
                    React.createElement("option", { value: "" }),
                    _.map(options.channels, function (channel, id) {
                        return (React.createElement("option", { key: id, value: channel }, channel));
                    }))));
        };
    }
    var countriesTableConfig = _.find(tableConfig, ['accessor', 'ip']);
    if (countriesTableConfig) {
        countriesTableConfig.Filter = function (_a) {
            var filter = _a.filter, onChange = _a.onChange;
            return (React.createElement("div", null,
                React.createElement("input", { list: "countries", name: "countries", onChange: function (event) { return onChange(event.target.value); }, style: { width: '100%' }, value: filter ? filter.value : '' }),
                React.createElement("datalist", { id: "countries" },
                    React.createElement("option", { value: "" }),
                    _.map(options.countries, function (country, id) {
                        return (React.createElement("option", { key: id, value: country }, country));
                    }))));
        };
    }
    var protocolsTableColumn = _.find(tableConfig, ['accessor', 'protocol']);
    if (protocolsTableColumn) {
        protocolsTableColumn.Filter = function (_a) {
            var filter = _a.filter, onChange = _a.onChange;
            return (React.createElement("div", null,
                React.createElement("input", { list: "protocols", name: "protocols", onChange: function (event) { return onChange(event.target.value); }, style: { width: '100%' }, value: filter ? filter.value : '' }),
                React.createElement("datalist", { id: "protocols" },
                    React.createElement("option", { value: "" }),
                    _.map(options.protocols, function (protocol, id) {
                        return (React.createElement("option", { key: id, value: protocol }, protocol));
                    }))));
        };
    }
    return tableConfig;
}
export default tableConfigOptions;
