import { combineReducers } from 'redux';
import streams from './streams';
import stream from './stream';
import subscribers from './subscribers';
import subscriber from './subscriber';
import channels from './channels';
import ips from './ips';
import user from './user';
import adminUsers from './admin/users';
import graphs from './graphs';
var reducers = combineReducers({
    streams: streams,
    stream: stream,
    subscribers: subscribers,
    subscriber: subscriber,
    channels: channels,
    ips: ips,
    user: user,
    adminUsers: adminUsers,
    graphs: graphs,
});
export default reducers;
