var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import AdminHeader from '../../../Shared/Components/AdminHeader/AdminHeader';
import { Alert } from 'reactstrap';
import usersTable from '../../../Shared/Tables/UsersTable';
import { getAction, getError, getData, putUser, } from '../../../../redux/admin/users';
var UsersPage = /** @class */ (function (_super) {
    __extends(UsersPage, _super);
    function UsersPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UsersPage.prototype.componentDidMount = function () {
        this.props.getAction();
    };
    UsersPage.prototype.render = function () {
        var _this = this;
        var _a = this.props.data.users, users = _a === void 0 ? [] : _a;
        var error = this.props.error;
        if (error)
            return React.createElement(Alert, { color: "danger" }, error);
        return (React.createElement("div", null,
            React.createElement(AdminHeader, null),
            React.createElement(ReactTable, { columns: usersTable(this.props.putUser), data: users, showPagination: true, showPageSizeOptions: false, minRows: 0, className: "-striped -highlight", defaultPageSize: 100, showPaginationTop: true }),
            React.createElement("button", { onClick: function () {
                    _this.props.getAction();
                } }, "Refresh")));
    };
    UsersPage = __decorate([
        connect(function (state) { return ({
            error: getError(state),
            data: getData(state),
        }); }, { getAction: getAction, putUser: putUser })
    ], UsersPage);
    return UsersPage;
}(Component));
export default UsersPage;
