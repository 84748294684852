import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Button } from 'reactstrap';
import * as jsonwebtoken from 'jsonwebtoken';
var tableConfigTemplate = [
    {
        Header: 'Time Registered',
        accessor: 'createdAt',
        Cell: function (props) {
            return moment(props.value).format('ddd D/MMM/YY HH:mm');
        },
    },
    {
        Header: 'Name',
        accessor: 'name',
        Cell: function (props) {
            var _a;
            return (props.value +
                (((_a = props.original.token === window.localStorage.getItem('token')) !== null && _a !== void 0 ? _a : '') ? ' (You)'
                    : ''));
        },
    },
    {
        Header: 'IP',
        accessor: 'ipUpdated',
    },
    {
        Header: 'Is Admin',
        accessor: 'isAdmin',
        Cell: function (props) {
            return props.value ? 'Yes' : 'No';
        },
        minWidth: 40,
    },
    {
        Header: 'Is Streamer',
        accessor: 'isStreamer',
        minWidth: 40,
    },
];
function tableConfigOptions(putUser) {
    var tableConfig = _.cloneDeep(tableConfigTemplate);
    var adminTableColumn = _.find(tableConfig, ['accessor', 'isAdmin']);
    adminTableColumn.Cell = function (props) {
        var _a;
        var userId;
        try {
            var tokenData = jsonwebtoken.decode((_a = window.localStorage.getItem('token')) !== null && _a !== void 0 ? _a : '');
            userId = tokenData.userId;
        }
        catch (error) { }
        return (React.createElement(Button, { outline: true, color: "primary", onClick: function () {
                putUser(props.original._id, {
                    isAdmin: !props.value,
                });
            }, block: true, disabled: props.original._id === userId }, props.value ? 'Yes' : 'No'));
    };
    var streamerTableColumn = _.find(tableConfig, ['accessor', 'isStreamer']);
    streamerTableColumn.Cell = function (props) {
        return (React.createElement(Button, { outline: true, color: "primary", onClick: function () {
                putUser(props.original._id, {
                    isStreamer: !props.value,
                });
            }, block: true }, props.value ? 'Yes' : 'No'));
    };
    return tableConfig;
}
export default tableConfigOptions;
