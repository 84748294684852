var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, } from 'recharts';
import _ from 'lodash';
import humanize from 'humanize-plus';
import createHistory from 'history/createBrowserHistory';
import qs from 'qs';
import humanizeDuration from 'humanize-duration';
import streamsTable from '../../Shared/Tables/StreamsTable';
import subscribersTable from '../../Shared/Tables/SubscribersTable';
var tickFormatter = function (tick) { return moment.unix(tick).format('ddd HH:mm'); };
var StreamWrapper = /** @class */ (function (_super) {
    __extends(StreamWrapper, _super);
    function StreamWrapper(props) {
        var _this = _super.call(this, props) || this;
        _this.fetchData = function () {
            _this.props.getData(_this.props.streamId, undefined, undefined, _this.state.filtered, _this.state.sorted);
            _this.buildQuery();
        };
        _this.buildQuery = function () {
            var history = createHistory();
            var query = {};
            _.forEach(_this.state.filtered, function (filter) {
                if (!query.filter)
                    query.filter = {};
                query.filter[filter.id] = filter.value;
            });
            _.forEach(_this.state.sorted, function (sort) {
                if (!query.sort)
                    query.sort = [];
                if (sort.desc) {
                    query.sort.push("-" + sort.id);
                }
                else {
                    query.sort.push(sort.id);
                }
            });
            query = qs.stringify(query, { arrayFormat: 'brackets' });
            history.push({
                search: query,
            });
        };
        _this.handleFilteredChange = function (column, value) {
            _this.setState({
                filtered: column,
            }, function () {
                _this.fetchData();
            });
        };
        _this.handleSortedChange = function (newSorted, column, shiftKey) {
            _this.setState({
                sorted: newSorted,
            }, function () {
                _this.fetchData();
            });
        };
        var state = {};
        state.filtered = _.map(props.searchParams.filter, function (paramKey, paramValue) {
            return {
                id: paramValue,
                value: paramKey,
            };
        });
        state.sorted = _.map(props.searchParams.sort, function (sort) {
            return {
                desc: _.startsWith(sort, '-'),
                id: _.replace(sort, /^-/, ''),
            };
        });
        _this.state = __assign({ filtered: [], sorted: [] }, state);
        _this.handleFilteredChange = _.debounce(_this.handleFilteredChange, 500);
        return _this;
    }
    StreamWrapper.prototype.render = function () {
        var _a = this.props, _b = _a.stream, stream = _b === void 0 ? null : _b, _c = _a.subscribers, subscribers = _c === void 0 ? [] : _c, _d = _a.options, options = _d === void 0 ? {} : _d, _e = _a.info, info = _e === void 0 ? {} : _e, _f = _a.relatedStreams, relatedStreams = _f === void 0 ? [] : _f, _g = _a.events, events = _g === void 0 ? [] : _g;
        var _h = info.totalBytes, totalBytes = _h === void 0 ? 0 : _h, _j = info.totalDuration, totalDuration = _j === void 0 ? 0 : _j, _k = info.totalPeakViewers, totalPeakViewers = _k === void 0 ? 0 : _k, _l = info.totalIPs, totalIPs = _l === void 0 ? 0 : _l;
        var streams = [];
        if (stream)
            streams.push(stream);
        return (React.createElement("div", null,
            "Stream",
            React.createElement(ReactTable, { columns: streamsTable(), data: streams, showPagination: false, minRows: 0, sortable: false }),
            "Related Streams: ",
            relatedStreams.length,
            React.createElement(ReactTable, { columns: streamsTable(), data: relatedStreams, showPagination: false, minRows: 0, sortable: false }),
            React.createElement(ResponsiveContainer, { width: "100%", height: 450 },
                React.createElement(LineChart, { data: _.map(events, function (event) {
                        event.unixTime = moment(event.time).unix();
                        event.subscribersCount = event.subscribers.length;
                        return event;
                    }), margin: { top: 20, right: 50, left: 5, bottom: 5 } },
                    React.createElement(XAxis, { dataKey: "unixTime", tickFormatter: tickFormatter, type: "number", scale: "time", domain: ['dataMin', 'dataMax'] }),
                    React.createElement(YAxis, null),
                    React.createElement(Tooltip, { labelFormatter: function (value) { return moment.unix(value).format('ddd HH:mm'); } }),
                    React.createElement(Legend, null),
                    React.createElement(Line, { name: "Viewers", type: "stepAfter", dataKey: "subscribersCount", stroke: "#82ca9d" }))),
            "Subscribers: ",
            subscribers.length,
            React.createElement("div", null,
                "Total Traffic: ",
                humanize.fileSize(totalBytes)),
            React.createElement("div", null,
                "Total Duration:",
                ' ',
                humanizeDuration(totalDuration * 1000, {
                    round: true,
                    largest: 3,
                })),
            React.createElement("div", null,
                "Total Peak Viewers: ",
                totalPeakViewers),
            React.createElement("div", null,
                "Unique IPs: ",
                totalIPs),
            React.createElement(ReactTable, { columns: subscribersTable(options, ['app', 'channel']), data: subscribers, showPagination: false, showPageSizeOptions: false, minRows: 0, defaultFiltered: this.state.filtered, defaultSorted: this.state.sorted, className: "-striped -highlight", onFilteredChange: this.handleFilteredChange, onSortedChange: this.handleSortedChange, filterable: true, manual: true })));
    };
    return StreamWrapper;
}(Component));
export default StreamWrapper;
