var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import { Button } from 'reactstrap';
import moment from 'moment';
import { config } from '../../config';
import AdminHeader from '../Shared/Components/AdminHeader/AdminHeader';
function generateColumns(updateDataFnc) {
    var _this = this;
    var tableConfigTemplate = [
        {
            Header: 'Channel Created',
            accessor: 'channelCreatedAt',
            Cell: function (props) {
                return moment(props.value).format('ddd D/MMM/YY HH:mm');
            },
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Type',
            accessor: 'type',
            Cell: function (props) {
                return (React.createElement(Button, { outline: true, color: "primary", onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0: return [4 /*yield*/, axios.put(config.STATS_HOST + "/admin/channels/" + props.original._id, {
                                        type: props.value === 'PRIVATE' ? 'PUBLIC' : 'PRIVATE',
                                    }, {
                                        headers: {
                                            'jwt-token': (_a = window.localStorage.getItem('token')) !== null && _a !== void 0 ? _a : '',
                                        },
                                    })];
                                case 1:
                                    _b.sent();
                                    return [4 /*yield*/, updateDataFnc()];
                                case 2:
                                    _b.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); }, block: true }, props.value));
            },
        },
    ];
    return tableConfigTemplate;
}
var AdminChannelsPage = /** @class */ (function (_super) {
    __extends(AdminChannelsPage, _super);
    function AdminChannelsPage(props) {
        var _this = _super.call(this, props) || this;
        _this.updateData = function () { return __awaiter(_this, void 0, void 0, function () {
            var channels;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, axios.get(config.STATS_HOST + "/admin/channels", {
                            headers: {
                                'jwt-token': (_a = window.localStorage.getItem('token')) !== null && _a !== void 0 ? _a : '',
                            },
                        })];
                    case 1:
                        channels = (_b.sent()).data.channels;
                        this.setState({
                            channels: channels,
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.state = {
            channels: [],
        };
        return _this;
    }
    AdminChannelsPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminChannelsPage.prototype.render = function () {
        var _this = this;
        var channels = this.state.channels;
        return (React.createElement("div", null,
            React.createElement(AdminHeader, null),
            React.createElement(ReactTable, { columns: generateColumns(this.updateData), data: channels, showPagination: true, showPageSizeOptions: false, minRows: 0, className: "-striped -highlight", defaultPageSize: 100, showPaginationTop: true }),
            React.createElement("button", { onClick: function () {
                    _this.props.getAction();
                } }, "Refresh")));
    };
    return AdminChannelsPage;
}(Component));
export default AdminChannelsPage;
