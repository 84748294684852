var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import qs from 'qs';
import { Redirect } from 'react-router';
var Login = /** @class */ (function (_super) {
    __extends(Login, _super);
    function Login() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Login.prototype.render = function () {
        var search = this.props.location.search;
        var searchParams = qs.parse(search, { ignoreQueryPrefix: true });
        if (searchParams.token) {
            var localStorage_1 = window.localStorage;
            localStorage_1.setItem('token', searchParams.token);
        }
        return React.createElement(Redirect, { to: "/" });
    };
    return Login;
}(Component));
export default Login;
