var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { handleActions } from 'redux-actions';
import axios from 'axios';
import _ from 'lodash';
import { config } from '../config';
var ACTION_GET = 'streams.get', ACTION_GET_SUCCESS = 'streams.get.success', ACTION_GET_FAILED = 'streams.get.failed';
//ACTIONS
export function getAction(limit, currentPage, filters, sorts) {
    if (limit === void 0) { limit = 20; }
    if (currentPage === void 0) { currentPage = 0; }
    if (filters === void 0) { filters = []; }
    if (sorts === void 0) { sorts = []; }
    return function (dispatch) {
        var _a;
        dispatch({ type: ACTION_GET });
        var params = {};
        _.forEach(filters, function (filter) {
            params[filter.id] = filter.value;
        });
        params.sort = [];
        _.forEach(sorts, function (sort) {
            if (sort.desc) {
                params.sort.push("-" + sort.id);
            }
            else {
                params.sort.push(sort.id);
            }
        });
        params.page = currentPage + 1;
        params.limit = limit;
        axios
            .get(config.STATS_HOST + "/streams", {
            headers: {
                'jwt-token': (_a = window.localStorage.getItem('token')) !== null && _a !== void 0 ? _a : '',
            },
            params: params,
        })
            .then(function (res) {
            dispatch({
                type: ACTION_GET_SUCCESS,
                data: res.data,
            });
        })
            .catch(function (e) {
            dispatch({
                type: ACTION_GET_FAILED,
                error: e.response.data.error,
            });
        });
    };
}
//REDUCER
var initialState = {
    error: null,
    data: {},
    isLoading: false,
};
var reducer = handleActions((_a = {},
    _a[ACTION_GET] = function (state, action) {
        return __assign(__assign({}, state), { isLoading: true });
    },
    _a[ACTION_GET_SUCCESS] = function (state, action) {
        return __assign(__assign({}, state), { data: action.data, isLoading: false, error: null });
    },
    _a[ACTION_GET_FAILED] = function (state, action) {
        return __assign(__assign({}, state), { error: action.error, isLoading: false });
    },
    _a), initialState);
export default reducer;
//SELECTORS
export var getError = function (state) { return state.streams.error; };
export var getData = function (state) { return state.streams.data; };
export var getLoading = function (state) { return state.streams.isLoading; };
