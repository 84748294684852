var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import ReactTable from 'react-table';
import _ from 'lodash';
import createHistory from 'history/createBrowserHistory';
import qs from 'qs';
import ipsTable from '../../Shared/Tables/IPsTable';
var IPsWrapper = /** @class */ (function (_super) {
    __extends(IPsWrapper, _super);
    function IPsWrapper(props) {
        var _this = _super.call(this, props) || this;
        _this.fetchData = function () {
            _this.props.getData(_this.state.pageSize, _this.state.page, _this.state.filtered, _this.state.sorted);
            _this.buildQuery();
        };
        _this.buildQuery = function () {
            var history = createHistory();
            var query = {};
            if (_this.state.page > 0) {
                query.page = _this.state.page + 1;
            }
            if (_this.state.pageSize !== 20) {
                query.pageSize = _this.state.pageSize;
            }
            _.forEach(_this.state.filtered, function (filter) {
                if (!query.filter)
                    query.filter = {};
                query.filter[filter.id] = filter.value;
            });
            _.forEach(_this.state.sorted, function (sort) {
                if (!query.sort)
                    query.sort = [];
                if (sort.desc) {
                    query.sort.push("-" + sort.id);
                }
                else {
                    query.sort.push(sort.id);
                }
            });
            query = qs.stringify(query, { arrayFormat: 'brackets' });
            history.push({
                search: query,
            });
        };
        _this.handlePageChange = function (pageIndex) {
            _this.setState({
                page: pageIndex,
            }, function () {
                _this.fetchData();
            });
        };
        _this.handlePageSizeChange = function (pageSize, pageIndex) {
            _this.setState({
                pageSize: pageSize,
            }, function () {
                _this.fetchData();
            });
        };
        _this.handleFilteredChange = function (column, value) {
            _this.setState({
                page: 0,
                filtered: column,
            }, function () {
                _this.fetchData();
            });
        };
        _this.handleSortedChange = function (newSorted, column, shiftKey) {
            _this.setState({
                sorted: newSorted,
            }, function () {
                _this.fetchData();
            });
        };
        var state = {};
        var page = parseInt(props.searchParams.page);
        if (!isNaN(page)) {
            state.page = page - 1;
        }
        var pageSize = parseInt(props.searchParams.pageSize);
        if (!isNaN(pageSize)) {
            state.pageSize = pageSize;
        }
        state.filtered = _.map(props.searchParams.filter, function (paramKey, paramValue) {
            return {
                id: paramValue,
                value: paramKey,
            };
        });
        state.sorted = _.map(props.searchParams.sort, function (sort) {
            return {
                desc: _.startsWith(sort, '-'),
                id: _.replace(sort, /^-/, ''),
            };
        });
        _this.state = __assign({ page: 0, pageSize: 20, filtered: [], sorted: [] }, state);
        _this.handleFilteredChange = _.debounce(_this.handleFilteredChange, 500);
        return _this;
    }
    IPsWrapper.prototype.render = function () {
        var _a = this.props, _b = _a.ips, ips = _b === void 0 ? [] : _b, _c = _a.options, options = _c === void 0 ? {} : _c, _d = _a.pages, pages = _d === void 0 ? 1 : _d, _e = _a.isLoading, isLoading = _e === void 0 ? false : _e;
        return (React.createElement("div", null,
            React.createElement(ReactTable, { columns: ipsTable(options), page: this.state.page, data: ips, pages: pages, loading: isLoading, defaultPageSize: this.state.pageSize, minRows: 0, defaultFiltered: this.state.filtered, defaultSorted: this.state.sorted, className: "-striped -highlight", showPaginationTop: true, onPageChange: this.handlePageChange, onPageSizeChange: this.handlePageSizeChange, onFilteredChange: this.handleFilteredChange, onSortedChange: this.handleSortedChange, filterable: true, manual: true })));
    };
    return IPsWrapper;
}(Component));
export default IPsWrapper;
