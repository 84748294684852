import React from 'react';
import { Route } from 'react-router-dom';
import StreamsPage from './modules/Streams/Containers/StreamsPage';
import StreamPage from './modules/Stream/Containers/StreamPage';
import SubscribersPage from './modules/Subscribers/Containers/SubscribersPage';
import SubscriberPage from './modules/Subscriber/Containers/SubscriberPage';
import ChannelsPage from './modules/Channels/Containers/ChannelsPage';
import GraphsPage from './modules/Graphs/Containers/GraphsPage';
import IPsPage from './modules/IPs/Containers/IPsPage';
import UserPage from './modules/User/Containers/UserPage';
import UsersPage from './modules/Admin/Users/Containers/UsersPage';
import LoginPage from './modules/Home/Containers/LoginPage/LoginPage';
import AdminChannelsPage from './modules/Admin/ChannelsPage';
var Routes = function () { return (React.createElement("div", null,
    React.createElement(Route, { exact: true, path: "/", component: ChannelsPage }),
    React.createElement(Route, { exact: true, path: "/streams", component: StreamsPage }),
    React.createElement(Route, { exact: true, path: "/streams/:id", component: StreamPage }),
    React.createElement(Route, { exact: true, path: "/subscribers", component: SubscribersPage }),
    React.createElement(Route, { exact: true, path: "/subscribers/:id", component: SubscriberPage }),
    React.createElement(Route, { exact: true, path: "/graphs", component: GraphsPage }),
    React.createElement(Route, { exact: true, path: "/graphs/:id", component: GraphsPage }),
    React.createElement(Route, { exact: true, path: "/ips", component: IPsPage }),
    React.createElement(Route, { exact: true, path: "/user", component: UserPage }),
    React.createElement(Route, { exact: true, path: "/admin/users", component: UsersPage }),
    React.createElement(Route, { exact: true, path: "/admin/channels", component: AdminChannelsPage }),
    React.createElement(Route, { exact: true, path: "/login", component: LoginPage }))); };
export default Routes;
