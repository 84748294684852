var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { config } from '../../../../config';
var handleLogout = function () {
    window.localStorage.removeItem('token');
};
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Header.prototype.render = function () {
        var _a;
        var isLoggedIn = (_a = !!window.localStorage.getItem('token')) !== null && _a !== void 0 ? _a : '';
        return (React.createElement(HeaderWrap, null,
            React.createElement(StyledLink, { exact: true, to: "/" }, "Home"),
            React.createElement(StyledLink, { to: "/streams" }, "Streams"),
            React.createElement(StyledLink, { to: "/subscribers" }, "Subscribers"),
            React.createElement(StyledLink, { to: "/ips" }, "IPs"),
            React.createElement(StyledLink, { exact: true, to: "/graphs" }, "Graphs"),
            !isLoggedIn ? (React.createElement("span", null,
                React.createElement(MyLink, { href: config.STATS_HOST + "/users/auth/google?redirectUri=" + encodeURIComponent(location.origin + "/login?token=") }, "Login"))) : (React.createElement("span", null,
                React.createElement(StyledLink, { to: "/", onClick: handleLogout, activeStyle: {
                        fontWeight: 'bold',
                        color: 'white',
                    } }, "Logout"),
                React.createElement(StyledLink, { exact: true, to: "/user" }, "User")))));
    };
    return Header;
}(Component));
export default Header;
var StyledLink = styled(NavLink)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0px 10px;\n  color: white;\n  cursor: pointer;\n  text-decoration: none;\n  font-weight: bold;\n  &.active {\n    color: red;\n  }\n"], ["\n  margin: 0px 10px;\n  color: white;\n  cursor: pointer;\n  text-decoration: none;\n  font-weight: bold;\n  &.active {\n    color: red;\n  }\n"])));
var HeaderWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  background-color: #262626;\n  padding: 15px 0px;\n"], ["\n  width: 100%;\n  background-color: #262626;\n  padding: 15px 0px;\n"])));
var MyLink = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0px 10px;\n  color: white;\n  cursor: pointer;\n  text-decoration: none;\n  font-weight: bold;\n  &.active {\n    color: red;\n  }\n"], ["\n  margin: 0px 10px;\n  color: white;\n  cursor: pointer;\n  text-decoration: none;\n  font-weight: bold;\n  &.active {\n    color: red;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
