import _ from 'lodash';
import strtotime from 'locutus/php/datetime/strtotime';
var defaultFilterMethod = function (filter, row, column) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var strings = ['app', 'channel', 'protocol'];
    var numbers = {
        bitrate: 1,
        bytes: 1024 * 1024,
        duration: 60,
        totalConnectionsCount: 1,
        peakViewersCount: 1,
    };
    var dates = ['connectCreated', 'connectUpdated'];
    var ips = ['ip'];
    switch (true) {
        case strings.includes(filter.id): {
            return new RegExp(filter.value, 'gi').test(row[filter.id]);
        }
        case Object.keys(numbers).includes(filter.id): {
            return _.gte(row[filter.id], filter.value * numbers[filter.id]);
        }
        case dates.includes(filter.id): {
            return _.gte(strtotime(row[filter.id]), strtotime(filter.value));
        }
        case ips.includes(filter.id): {
            return (new RegExp(filter.value, 'gi').test(row._original.ip) ||
                !row._original.location ||
                new RegExp(filter.value, 'gi').test((_b = (_a = row._original.location) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.country) ||
                new RegExp(filter.value, 'gi').test((_d = (_c = row._original.location) === null || _c === void 0 ? void 0 : _c.api) === null || _d === void 0 ? void 0 : _d.city) ||
                new RegExp(filter.value, 'gi').test((_f = (_e = row._original.location) === null || _e === void 0 ? void 0 : _e.api) === null || _f === void 0 ? void 0 : _f.isp) ||
                new RegExp(filter.value, 'gi').test((_h = (_g = row._original.location) === null || _g === void 0 ? void 0 : _g.api) === null || _h === void 0 ? void 0 : _h.countryCode) ||
                new RegExp(filter.value, 'gi').test((_k = (_j = row._original.location) === null || _j === void 0 ? void 0 : _j.api) === null || _k === void 0 ? void 0 : _k.message));
        }
        default: {
            return true;
        }
    }
};
export default defaultFilterMethod;
