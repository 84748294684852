var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import ReactTable from 'react-table';
import _ from 'lodash';
import streamsTable from '../../Shared/Tables/StreamsTable';
import subscribersTable from '../../Shared/Tables/SubscribersTable';
import defaultFilterMethod from '../../Shared/Methods/TableFilter';
var StreamWrapper = /** @class */ (function (_super) {
    __extends(StreamWrapper, _super);
    function StreamWrapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StreamWrapper.prototype.render = function () {
        var _a = this.props, _b = _a.subscriber, subscriber = _b === void 0 ? null : _b, _c = _a.streams, streams = _c === void 0 ? [] : _c;
        var subscribers = [];
        if (subscriber)
            subscribers.push(subscriber);
        return (React.createElement("div", null,
            "Subscriber",
            React.createElement(ReactTable, { columns: subscribersTable(), data: subscribers, showPagination: false, minRows: 0, sortable: false }),
            "Streams: ",
            streams.length,
            React.createElement(ReactTable, { columns: streamsTable({
                    apps: _.chain(streams).map('app').uniq().value(),
                    channels: _.chain(streams).map('channel').uniq().value(),
                    countries: _.chain(streams)
                        .map('countryCode')
                        .compact()
                        .uniq()
                        .value(),
                }), data: streams, minRows: 0, defaultFilterMethod: defaultFilterMethod, filterable: true })));
    };
    return StreamWrapper;
}(Component));
export default StreamWrapper;
