var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { Alert } from 'reactstrap';
import IPsWrapper from '../Components/IPsWrapper';
import { getAction, getError, getData, getLoading } from '../../../redux/ips';
var IPsPage = /** @class */ (function (_super) {
    __extends(IPsPage, _super);
    function IPsPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IPsPage.prototype.componentDidMount = function () {
        this.props.getAction();
    };
    IPsPage.prototype.render = function () {
        var _this = this;
        var _a = this.props.data, _b = _a.ips, ips = _b === void 0 ? [] : _b, _c = _a.options, options = _c === void 0 ? {} : _c, _d = _a.info, info = _d === void 0 ? {} : _d, total = _a.total, limit = _a.limit, page = _a.page, pages = _a.pages;
        var totalCountries = info.totalCountries, totalCities = info.totalCities, totalISPs = info.totalISPs;
        var search = this.props.location.search;
        var _e = this.props, error = _e.error, isLoading = _e.isLoading;
        if (error)
            return React.createElement(Alert, { color: "danger" }, error);
        var searchParams = qs.parse(search, { ignoreQueryPrefix: true });
        return (React.createElement("div", null,
            React.createElement(IPsWrapper, { ips: ips, options: options, pages: pages, getData: this.props.getAction, searchParams: searchParams, isLoading: isLoading }),
            React.createElement("div", null,
                "Total Counties: ",
                totalCountries),
            React.createElement("div", null,
                "Total Cities: ",
                totalCities),
            React.createElement("div", null,
                "Total ISPs: ",
                totalISPs),
            React.createElement("br", null),
            React.createElement("div", null,
                "Showing: ",
                ips.length),
            React.createElement("div", null,
                "Total: ",
                total),
            React.createElement("div", null,
                "Limit: ",
                limit),
            React.createElement("div", null,
                "Page: ",
                page),
            React.createElement("div", null,
                "Pages: ",
                pages),
            React.createElement("button", { onClick: function () {
                    _this.props.getAction();
                } }, "Refresh")));
    };
    IPsPage = __decorate([
        connect(function (state) { return ({
            error: getError(state),
            data: getData(state),
            isLoading: getLoading(state),
        }); }, { getAction: getAction })
    ], IPsPage);
    return IPsPage;
}(Component));
export default IPsPage;
