var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import humanize from 'humanize-plus';
import qs from 'qs';
import humanizeDuration from 'humanize-duration';
import { Alert } from 'reactstrap';
import StreamsWrapper from '../Components/StreamsWrapper';
import { getAction, getError, getData, getLoading, } from '../../../redux/streams';
var StreamsPage = /** @class */ (function (_super) {
    __extends(StreamsPage, _super);
    function StreamsPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StreamsPage.prototype.componentDidMount = function () {
        this.props.getAction();
    };
    StreamsPage.prototype.render = function () {
        var _this = this;
        var _a = this.props.data, _b = _a.streams, streams = _b === void 0 ? [] : _b, _c = _a.options, options = _c === void 0 ? {} : _c, _d = _a.info, info = _d === void 0 ? {} : _d, total = _a.total, limit = _a.limit, page = _a.page, pages = _a.pages;
        var _e = info.totalBytes, totalBytes = _e === void 0 ? 0 : _e, _f = info.totalDuration, totalDuration = _f === void 0 ? 0 : _f, _g = info.totalConnections, totalConnections = _g === void 0 ? 0 : _g, _h = info.totalPeakViewers, totalPeakViewers = _h === void 0 ? 0 : _h, _j = info.totalIPs, totalIPs = _j === void 0 ? 0 : _j;
        var search = this.props.location.search;
        var _k = this.props, error = _k.error, isLoading = _k.isLoading;
        if (error)
            return React.createElement(Alert, { color: "danger" }, error);
        var searchParams = qs.parse(search, { ignoreQueryPrefix: true });
        return (React.createElement("div", null,
            React.createElement(StreamsWrapper, { streams: streams, options: options, pages: pages, getData: this.props.getAction, searchParams: searchParams, isLoading: isLoading }),
            React.createElement("div", null,
                "Total Traffic: ",
                humanize.fileSize(totalBytes)),
            React.createElement("div", null,
                "Total Duration:",
                ' ',
                humanizeDuration(totalDuration * 1000, {
                    round: true,
                    largest: 3,
                })),
            React.createElement("div", null,
                "Total Connections: ",
                totalConnections),
            React.createElement("div", null,
                "Total Peak Viewers: ",
                totalPeakViewers),
            React.createElement("div", null,
                "Unique IPs: ",
                totalIPs),
            React.createElement("br", null),
            React.createElement("div", null,
                "Showing: ",
                streams.length),
            React.createElement("div", null,
                "Total: ",
                total),
            React.createElement("div", null,
                "Limit: ",
                limit),
            React.createElement("div", null,
                "Page: ",
                page),
            React.createElement("div", null,
                "Pages: ",
                pages),
            React.createElement("button", { onClick: function () {
                    _this.props.getAction();
                } }, "Refresh")));
    };
    StreamsPage = __decorate([
        connect(function (state) { return ({
            error: getError(state),
            data: getData(state),
            isLoading: getLoading(state),
        }); }, { getAction: getAction })
    ], StreamsPage);
    return StreamsPage;
}(Component));
export default StreamsPage;
