import React from 'react';
import moment from 'moment';
import _ from 'lodash';
var tableConfigTemplate = [
    {
        Header: 'Time Added',
        accessor: 'createdAt',
        Cell: function (props) {
            return moment(props.value).format('ddd D/MMM/YY HH:mm');
        },
    },
    {
        Header: 'IP',
        accessor: 'ip',
    },
    {
        Header: 'Country',
        accessor: 'api.country',
        Cell: function (props) {
            if (props.original.api.status !== 'success')
                return props.original.api.message;
            return props.original.api.country;
        },
    },
    {
        Header: 'City',
        accessor: 'api.city',
    },
    {
        Header: 'ISP',
        accessor: 'api.isp',
    },
];
function tableConfigOptions(options, disableFiltering) {
    if (options === void 0) { options = {}; }
    if (disableFiltering === void 0) { disableFiltering = []; }
    var tableConfig = _.cloneDeep(tableConfigTemplate);
    _.forEach(disableFiltering, function (columnName) {
        var tableColumn = _.find(tableConfig, ['accessor', columnName]);
        if (tableColumn) {
            tableColumn.filterable = false;
        }
    });
    var countriesTableColumn = _.find(tableConfig, ['accessor', 'api.country']);
    if (countriesTableColumn) {
        countriesTableColumn.Filter = function (_a) {
            var filter = _a.filter, onChange = _a.onChange;
            return (React.createElement("div", null,
                React.createElement("input", { list: "countries", name: "countries", onChange: function (event) { return onChange(event.target.value); }, style: { width: '100%' }, value: filter ? filter.value : '' }),
                React.createElement("datalist", { id: "countries" },
                    React.createElement("option", { value: "" }),
                    _.map(options.countries, function (country, id) {
                        return (React.createElement("option", { key: id, value: country }, country));
                    }))));
        };
    }
    var citiesTableColumn = _.find(tableConfig, ['accessor', 'api.city']);
    if (citiesTableColumn) {
        citiesTableColumn.Filter = function (_a) {
            var filter = _a.filter, onChange = _a.onChange;
            return (React.createElement("div", null,
                React.createElement("input", { list: "cities", name: "cities", onChange: function (event) { return onChange(event.target.value); }, style: { width: '100%' }, value: filter ? filter.value : '' }),
                React.createElement("datalist", { id: "cities" },
                    React.createElement("option", { value: "" }),
                    _.map(options.cities, function (city, id) {
                        return (React.createElement("option", { key: id, value: city }, city));
                    }))));
        };
    }
    var ISPsTableColumn = _.find(tableConfig, ['accessor', 'api.isp']);
    if (ISPsTableColumn) {
        ISPsTableColumn.Filter = function (_a) {
            var filter = _a.filter, onChange = _a.onChange;
            return (React.createElement("div", null,
                React.createElement("input", { list: "isps", name: "isps", onChange: function (event) { return onChange(event.target.value); }, style: { width: '100%' }, value: filter ? filter.value : '' }),
                React.createElement("datalist", { id: "isps" },
                    React.createElement("option", { value: "" }),
                    _.map(options.ISPs, function (isp, id) {
                        return (React.createElement("option", { key: id, value: isp }, isp));
                    }))));
        };
    }
    var protocolsTableColumn = _.find(tableConfig, ['accessor', 'protocol']);
    if (protocolsTableColumn) {
        protocolsTableColumn.Filter = function (_a) {
            var filter = _a.filter, onChange = _a.onChange;
            return (React.createElement("div", null,
                React.createElement("input", { list: "protocols", name: "protocols", onChange: function (event) { return onChange(event.target.value); }, style: { width: '100%' }, value: filter ? filter.value : '' }),
                React.createElement("datalist", { id: "protocols" },
                    React.createElement("option", { value: "" }),
                    _.map(options.protocols, function (protocol) {
                        return React.createElement("option", { value: protocol }, protocol);
                    }))));
        };
    }
    return tableConfig;
}
export default tableConfigOptions;
