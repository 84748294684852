var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Button, } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import AdminHeader from '../../Shared/Components/AdminHeader/AdminHeader';
import { getAction, getError, getData } from '../../../redux/user';
var UserPage = /** @class */ (function (_super) {
    __extends(UserPage, _super);
    function UserPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UserPage.prototype.componentDidMount = function () {
        this.props.getAction();
    };
    UserPage.prototype.render = function () {
        var user = this.props.data.user;
        var error = this.props.error;
        if (error)
            return React.createElement(Alert, { color: "danger" }, error);
        if (!user)
            return React.createElement(Alert, { color: "info" }, "Loading...");
        return (React.createElement("div", null,
            user.isAdmin ? React.createElement(AdminHeader, null) : null,
            React.createElement(ListGroup, null,
                React.createElement(ListGroupItem, null,
                    React.createElement(ListGroupItemHeading, null, "Name"),
                    React.createElement(ListGroupItemText, null, user.name)),
                React.createElement(ListGroupItem, null,
                    React.createElement(ListGroupItemHeading, null, "Is Admin"),
                    React.createElement(ListGroupItemText, null, user.isAdmin ? 'Yes' : 'No')),
                React.createElement(ListGroupItem, null,
                    React.createElement(ListGroupItemHeading, null, "Is Streamer"),
                    React.createElement(ListGroupItemText, null, user.isStreamer ? 'Yes' : 'No')),
                React.createElement(ListGroupItem, null,
                    React.createElement(ListGroupItemHeading, null, "API Key"),
                    React.createElement(ListGroupItemText, null,
                        user.token,
                        React.createElement(CopyToClipboard, { text: user.token },
                            React.createElement(Button, { color: "primary" }, "Copy")))),
                React.createElement(ListGroupItem, null,
                    React.createElement(ListGroupItemHeading, null, "Stream Key"),
                    React.createElement(ListGroupItemText, null,
                        user.streamKey,
                        React.createElement(CopyToClipboard, { text: user.streamKey },
                            React.createElement(Button, { color: "primary" }, "Copy")))),
                React.createElement(ListGroupItem, null,
                    React.createElement(ListGroupItemHeading, null, "Date Created"),
                    React.createElement(ListGroupItemText, null, moment(user.createdAt).format('ddd D/MMM/YY HH:mm'))))));
    };
    UserPage = __decorate([
        connect(function (state) { return ({
            error: getError(state),
            data: getData(state),
        }); }, { getAction: getAction })
    ], UserPage);
    return UserPage;
}(Component));
export default UserPage;
