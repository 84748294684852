var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip, Legend, ResponsiveContainer, LineChart, XAxis, YAxis, Line, CartesianGrid, BarChart, Bar, } from 'recharts';
import _ from 'lodash';
import moment from 'moment';
import { Alert } from 'reactstrap';
import humanizeDuration from 'humanize-duration';
import { withRouter } from 'react-router-dom';
import { getAction, getError, getData, getLoading, } from '../../../redux/graphs';
var DAYS_OF_THE_WEEK = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
var GraphsPage = /** @class */ (function (_super) {
    __extends(GraphsPage, _super);
    function GraphsPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GraphsPage.prototype.componentDidMount = function () {
        var id = this.props.match.params.id || '';
        this.props.getAction(id);
    };
    GraphsPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, error = _a.error, isLoading = _a.isLoading;
        if (error)
            return React.createElement(Alert, { color: "danger" }, error);
        if (isLoading)
            return React.createElement(Alert, { color: "info" }, "Loading...");
        var _b = this.props.data, _c = _b.totalDurationStreams, totalDurationStreams = _c === void 0 ? [] : _c, _d = _b.totalDurationSubscribers, totalDurationSubscribers = _d === void 0 ? [] : _d, _e = _b.monthlyStatsStreams, monthlyStatsStreams = _e === void 0 ? [] : _e, _f = _b.monthlyStatsSubscribers, monthlyStatsSubscribers = _f === void 0 ? [] : _f, _g = _b.dayOfWeekStatsStreams, dayOfWeekStatsStreams = _g === void 0 ? [] : _g, _h = _b.dayOfWeekStatsSubscribers, dayOfWeekStatsSubscribers = _h === void 0 ? [] : _h, _j = _b.timeOfDayStatsStreams, timeOfDayStatsStreams = _j === void 0 ? [] : _j, _k = _b.timeOfDayStatsSubscribers, timeOfDayStatsSubscribers = _k === void 0 ? [] : _k, _l = _b.topStreamers, topStreamers = _l === void 0 ? [] : _l, _m = _b.user, user = _m === void 0 ? null : _m;
        var totalDurationStreamsData = totalDurationStreams.map(function (item) {
            return {
                name: item._id || 'Local Network',
                value: Math.round(item.totalDurationSeconds / 60 / 60),
            };
        });
        var totalDurationSubsData = totalDurationSubscribers.map(function (item) {
            return {
                name: item._id || 'Local Network',
                value: Math.round(item.totalDurationSeconds / 60 / 60),
            };
        });
        var lineChartDataMonthlyStatsStreams = monthlyStatsStreams.map(function (item) {
            var year = item._id.year;
            var month = item._id.month;
            var subsItem = _.find(monthlyStatsSubscribers, {
                _id: { year: year, month: month },
            });
            return {
                name: item._id.year + "/" + item._id.month,
                value: Math.round(item.totalDurationSeconds / 60 / 60),
                valueSubs: Math.round((subsItem === null || subsItem === void 0 ? void 0 : subsItem.totalDurationSeconds) / 60 / 60) || 0,
            };
        });
        var barChartDataDayOfWeekStatsStreams = dayOfWeekStatsStreams.map(function (item) {
            var subsItem = _.find(dayOfWeekStatsSubscribers, {
                _id: item._id,
            });
            return {
                name: item._id,
                value: Math.round(item.totalDurationSeconds / 60 / 60),
                valueSubs: Math.round((subsItem === null || subsItem === void 0 ? void 0 : subsItem.totalDurationSeconds) / 60 / 60) || 0,
            };
        });
        var barChartTimeOfDayStatsStreams = timeOfDayStatsStreams.map(function (item) {
            var subsItem = _.find(timeOfDayStatsSubscribers, {
                _id: item._id,
            });
            return {
                name: item._id,
                value: Math.round(item.totalDurationSeconds / 60 / 60),
                valueSubs: Math.round((subsItem === null || subsItem === void 0 ? void 0 : subsItem.totalDurationSeconds) / 60 / 60) || 0,
            };
        });
        var topStreamersData = topStreamers.map(function (item) {
            return {
                name: item.user.name,
                value: Math.round(item.totalDurationSeconds / 60 / 60),
                userId: item.user._id,
            };
        });
        return (React.createElement("div", null,
            user && React.createElement(Alert, { color: "primary" }, user.name),
            React.createElement("div", null,
                React.createElement(BarChart, { width: 900, height: 350, data: totalDurationStreamsData, margin: { top: 20, right: 50, left: 5, bottom: 5 } },
                    React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
                    React.createElement(XAxis, { dataKey: "name" }),
                    React.createElement(YAxis, null),
                    React.createElement(Tooltip, { formatter: function (value) {
                            return humanizeDuration(value * 60 * 60 * 1000, {
                                round: true,
                                largest: 2,
                            });
                        } }),
                    React.createElement(Legend, null),
                    React.createElement(Bar, { stackId: 0, name: "Hours Streamed", dataKey: "value", fill: "#8884d8" })),
                React.createElement(BarChart, { width: 900, height: 350, data: totalDurationSubsData, margin: { top: 20, right: 50, left: 5, bottom: 5 } },
                    React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
                    React.createElement(XAxis, { dataKey: "name" }),
                    React.createElement(YAxis, null),
                    React.createElement(Tooltip, { formatter: function (value) {
                            return humanizeDuration(value * 60 * 60 * 1000, {
                                round: true,
                                largest: 2,
                            });
                        } }),
                    React.createElement(Legend, null),
                    React.createElement(Bar, { stackId: 0, name: "Hours Viewed", dataKey: "value", fill: "#82ca9d" })),
                React.createElement(BarChart, { width: 900, height: 350, data: topStreamersData, margin: { top: 20, right: 50, left: 5, bottom: 5 }, onClick: function (data) {
                        var userId = data.activePayload[0].payload.userId;
                        _this.props.history.push("/graphs/" + userId);
                    } },
                    React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
                    React.createElement(XAxis, { dataKey: "name" }),
                    React.createElement(YAxis, null),
                    React.createElement(Tooltip, { formatter: function (value) {
                            return humanizeDuration(value * 60 * 60 * 1000, {
                                round: true,
                                largest: 2,
                            });
                        } }),
                    React.createElement(Legend, null),
                    React.createElement(Bar, { stackId: 0, name: "Hours Streamed", dataKey: "value", fill: "#8884d8" }))),
            React.createElement("div", null,
                React.createElement(ResponsiveContainer, { width: "100%", height: 720 },
                    React.createElement(LineChart, { width: 900, height: 350, data: lineChartDataMonthlyStatsStreams, margin: { top: 5, right: 30, left: 20, bottom: 5 } },
                        React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
                        React.createElement(XAxis, { dataKey: "name" }),
                        React.createElement(YAxis, null),
                        React.createElement(Tooltip, null),
                        React.createElement(Legend, null),
                        React.createElement(Line, { name: "Hours Streamed", type: "monotone", dataKey: "value", stroke: "#8884d8" }),
                        React.createElement(Line, { name: "Hours Viewed", type: "monotone", dataKey: "valueSubs", stroke: "#82ca9d" })))),
            React.createElement("div", null,
                React.createElement(BarChart, { width: 900, height: 350, data: barChartDataDayOfWeekStatsStreams },
                    React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
                    React.createElement(XAxis, { dataKey: "name", tickFormatter: function (value) { return DAYS_OF_THE_WEEK[value - 1]; } }),
                    React.createElement(YAxis, null),
                    React.createElement(Tooltip, { labelFormatter: function (value) { return DAYS_OF_THE_WEEK[value - 1]; } }),
                    React.createElement(Legend, null),
                    React.createElement(Bar, { stackId: 0, name: "Hours Streamed", dataKey: "value", fill: "#8884d8" }),
                    React.createElement(Bar, { stackId: 0, name: "Hours Viewed", dataKey: "valueSubs", fill: "#82ca9d" }))),
            React.createElement("div", null,
                React.createElement(BarChart, { width: 900, height: 350, data: barChartTimeOfDayStatsStreams },
                    React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
                    React.createElement(XAxis, { dataKey: "name", tickFormatter: function (value) {
                            return moment.utc(value * 3600 * 1000).format('HH:mm');
                        } }),
                    React.createElement(YAxis, null),
                    React.createElement(Tooltip, { labelFormatter: function (value) {
                            return moment.utc(value * 3600 * 1000).format('HH:mm');
                        } }),
                    React.createElement(Legend, null),
                    React.createElement(Bar, { stackId: 0, name: "Hours Streamed", dataKey: "value", fill: "#8884d8" }),
                    React.createElement(Bar, { stackId: 0, name: "Hours Viewed", dataKey: "valueSubs", fill: "#82ca9d" })))));
    };
    GraphsPage = __decorate([
        connect(function (state) { return ({
            error: getError(state),
            data: getData(state),
            isLoading: getLoading(state),
        }); }, { getAction: getAction })
    ], GraphsPage);
    return GraphsPage;
}(Component));
export default withRouter(GraphsPage);
