var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { handleActions } from 'redux-actions';
import axios from 'axios';
import { config } from '../../config';
var ACTION_GET = 'adminUsers.get', ACTION_GET_SUCCESS = 'adminUsers.get.success', ACTION_GET_FAILED = 'adminUsers.get.failed', ACTION_PUT = 'adminUsers.put', ACTION_PUT_SUCCESS = 'adminUsers.put.success', ACTION_PUT_FAILED = 'adminUsers.put.failed';
//ACTIONS
export function getAction() {
    return function (dispatch) {
        var _a;
        dispatch({ type: ACTION_GET });
        axios
            .get(config.STATS_HOST + "/admin/users", {
            headers: {
                'jwt-token': (_a = window.localStorage.getItem('token')) !== null && _a !== void 0 ? _a : '',
            },
        })
            .then(function (res) {
            dispatch({
                type: ACTION_GET_SUCCESS,
                data: res.data,
            });
        })
            .catch(function (e) {
            dispatch({
                type: ACTION_GET_FAILED,
                error: e.response.data.error,
            });
        });
    };
}
export function putUser(id, data) {
    return function (dispatch) {
        var _a;
        dispatch({ type: ACTION_PUT });
        axios
            .put(config.STATS_HOST + "/admin/users/" + id, data, {
            headers: {
                'jwt-token': (_a = window.localStorage.getItem('token')) !== null && _a !== void 0 ? _a : '',
            },
        })
            .then(function (res) {
            dispatch({
                type: ACTION_PUT_SUCCESS,
                user: res.data.user,
            });
        })
            .catch(function (e) {
            dispatch({
                type: ACTION_PUT_FAILED,
                error: e.response.data.error,
            });
        });
    };
}
function updateObjectInArray(users, action) {
    return users.map(function (item, index) {
        if (item._id !== action.user._id) {
            return item;
        }
        return __assign(__assign({}, item), action.user);
    });
}
//REDUCER
var initialState = {
    error: null,
    data: {},
};
var reducer = handleActions((_a = {},
    _a[ACTION_GET_SUCCESS] = function (state, action) {
        return __assign(__assign({}, state), { data: action.data, error: null });
    },
    _a[ACTION_GET_FAILED] = function (state, action) {
        return __assign(__assign({}, state), { error: action.error });
    },
    _a[ACTION_PUT_SUCCESS] = function (state, action) {
        return __assign(__assign({}, state), { data: {
                users: updateObjectInArray(state.data.users, action),
            }, error: null });
    },
    _a[ACTION_PUT_FAILED] = function (state, action) {
        return __assign(__assign({}, state), { error: action.error });
    },
    _a), initialState);
export default reducer;
//SELECTORS
export var getError = function (state) { return state.adminUsers.error; };
export var getData = function (state) { return state.adminUsers.data; };
